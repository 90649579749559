<template>
  <div
    ref="accordion"
    class="relative"
    :class="[
      component.wrapper,
      component.border
    ]"
  >
    <!-- eslint-disable vue/no-v-html -->
    <input
      ref="input"
      v-model="status"
      type="checkbox"
      name="accordion"
      :class="{
        'h-full': clickableFull
      }"
      class="peer appearance-none absolute w-full cursor-pointer row-start-1"
    />

    <label
      class="accordion__title flex justify-between align-middle pr-3"
      :class="component.title"
    >
      <div
        class="flex items-center justify-center gap-4"
        :class="{
          'w-full': !clickableFull
        }"
      >
        <slot name="title-icon" />

        <div
          v-if="_isEmpty($slots.title)"
          v-html="title"
        >
        </div>
        <slot
          v-else
          name="title"
        />
      </div>

      <template v-if="layout === 'dropdown'">
        <IconsChevron :direction="status ? 'top' : 'bottom'" />
      </template>
    </label>

    <template v-if="layout === 'default'">
      <template v-if="iconType === 'default'">
        <NuxtImg
          src="/icons/plus.svg"
          width="15"
          height="14"
          alt="accordion-open"
          loading="lazy"
          class="inline-grid peer-checked:hidden self-center justify-self-center"
        />

        <NuxtImg
          src="/icons/minus.svg"
          width="9"
          height="3"
          alt="accordion-close"
          loading="lazy"
          class="hidden peer-checked:inline-grid self-center justify-self-center"
        />
      </template>

      <template v-else-if="iconType === 'arrow'">
        <IconsChevron
          direction="bottom"
          fill="#193560"
          class="inline-grid peer-checked:hidden self-center justify-self-center"
        />

        <IconsChevron
          direction="top"
          fill="#193560"
          class="hidden peer-checked:inline-grid self-center justify-self-center"
        />
      </template>
    </template>

    <div
      class="accordion__content overflow-hidden max-h-0 z-10 peer-checked:max-h-max"
      :class="component.content"
      @click.stop
    >
      <div
        v-if="_isEmpty($slots.content)"
        v-html="content"
      >
      </div>

      <slot
        v-else
        name="content"
      />
    </div>
  </div>
</template>

<script setup>
import _isEmpty from 'underscore/cjs/isEmpty.js'

defineOptions({
  name: 'AtomsAccordion'
})

const props = defineProps({
  border: {
    type: Boolean,
    default: true
  },

  open: {
    type: Boolean,
    default: false
  },

  size: {
    type: String,
    default: 'default',
    validator: value => ['default', 'medium', 'small', 'xs'].includes(value)
  },

  layout: {
    type: String,
    default: 'default',
    validator: value => ['default', 'dropdown'].includes(value)
  },

  borderColor: {
    type: String,
    default: 'border-primary'
  },

  fullBorder: {
    type: Boolean,
    default: false
  },

  // Need to handle status on slots
  clickableFull: {
    type: Boolean,
    default: true
  },

  noPadding: {
    type: Boolean,
    default: false
  },

  iconType: {
    type: String,
    default: 'default',
    validator: value => ['default', 'arrow'].includes(value)
  },

  showTitleIcon: {
    type: Boolean,
    default: false
  },

  inGuides: {
    type: Boolean,
    default: false
  },

  title: {
    type: String,
    default: ''
  },

  content: {
    type: String,
    default: ''
  },

  indexValue: {
    type: Number,
    default: 0
  },

  listSize: {
    type: Number,
    default: 0
  },

  disableInitialBorder: {
    type: Boolean,
    default: false
  },

  disableLastBorder: {
    type: Boolean,
    default: false
  }
})

const $slots = useSlots()
const status = ref(props.open)

defineExpose({
  status
})

const component = computed(() => {
  const wrapper = getKey(props.layout, {
    default: 'text-lg/[1.625rem] grid grid-cols-[auto_0.9375rem]',
    dropdown: 'text-base'
  })

  let border = getKey(props.layout, {
    default: props.border
      ? 'border-t-1 border-dark/20 last-of-type:border-b-1'
      : '',
    dropdown: props.fullBorder ? `rounded-lg border-1 ${props.borderColor} mb-2` : ''
  })

  if (props.disableInitialBorder && props.indexValue === 0) {
    border = border.replace('border-t-1', '')
  }

  if (props.disableLastBorder && props.indexValue === props.listSize - 1) {
    border = border.replace('last-of-type:border-b-1', '')
  }

  const title = getKey(props.layout, {
    default: `
      font-bold row-start-1
      ${props.inGuides ? 'text-2xl peer-checked:pb-4' : 'peer-checked:pb-[1.3125rem]'}
      ${
        !props.inGuides && getKey(props.size, {
          default: 'py-6 sm:py-8',
          medium: 'py-2 sm:py-5',
          small: 'py-2 sm:py-4',
          xs: 'py-2'
        })
      }
    `,
    dropdown: `
      flex items-center ${props.fullBorder ? 'rounded-lg bg-white' : 'rounded-lg border-1 mb-2 bg-gray-5'} py-3 px-4 text-base
      ${
        status
          ? props.borderColor
          : 'border-gray-400'
      }
    `
  })

  const content = getKey(props.layout, {
    default: props.size === 'small'
      ? 'peer-checked:pb-8 peer-checked:sm:pb-10'
      : 'peer-checked:pb-2 sm:peer-checked:pb-4',
    dropdown: 'peer-checked:pb-4'
  })

  return {
    wrapper,
    border,
    title: removeClassExtras(title),
    content
  }
})

watch(() => props.open, value => {
  status.value = value
}, { deep: true, immediate: true })
</script>
